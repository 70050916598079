import Vue, { FunctionalComponentOptions, VueConstructor } from 'vue';
import { DefaultProps, RecordPropsDefinition } from 'vue/types/options';
import Organisation from './models/Organisation';

export interface Resource<T> {
  data: T;
}

export interface ResourceCollection<T> {
  data: T[];
}

export interface PaginatedRequestParams {
  page?: number | string;
  limit?: number | string;
}

export type PaginatedParams<R> = R & PaginatedRequestParams;

export interface PaginatedUpstreamClientParams extends PaginatedRequestParams {
  entity_id?: string[] | null;
}

export interface UpstreamSubFundAnalysisFilters {
  investment_id?: string[];
  entity_id?: string[];
  root_investment_id?: string[];
  root_id?: string[];
  downstream_root_investment_id?: string[];
  downstream_investment_id?: string[];
  subfund_name?: string;
  upstream_client_name?: string;
  fiduciary_name?: string;
  fiduciary_id?: string[];
  time_period_id?: string[];
  page?: number;
  limit?: number;
}

export interface PaginationLinks {
  first?: string;
  last?: string;
  prev?: string;
  next?: string;
}

export interface PaginationMeta {
  current_page?: number;
  from?: number;
  last_page?: number;
  path?: string;
  per_page?: number;
  to?: number;
  total?: number;
}

export interface Paginated<T> {
  data: T[];
  meta: PaginationMeta;
  links: PaginationLinks;
}

export interface ActionSelectorItem {
  label: string;
  slug: string;
  permissionRequired: string;
  title: string;
  action: () => void;
  disabled?: boolean;
}

export type PaginationInfo = PaginationMeta & PaginationLinks;

export type CustomRenderOptions = {
  name?: string;

  metaPropKey?: string;

  component?: VueConstructor;

  parent?: Vue;

  props?: object | ((row: any) => object);

  listeners?: any;
};

export enum UnitType {
  GBP = 'gbp',
  PERCENT = 'percent',
  BPS = 'bps',
}

export type LinearProgressCellInput = {
  value: number;
  displayText?: string;
  color?: string;
};

export type ValidatedUserDetails = {
  id?: string;
  email: string;
  name?: string;
  validUser?: boolean;
  organizations?: Organisation[];
};

export type Slug = {
  name: string;
  slug: string;
};

export type TagType = 'GENERAL' | 'QC';

export enum ResourceTagContextType {
  YEAR_ON_YEAR = 'year_on_year',
  BENCHMARKING = 'bechmarking',
  TEMPLATE_VS_PLATFORM = 'template_vs_platform',
  CROSS_CLIENT_COMPARISON = 'cross_client_comparison',
}
