export const FETCH_PRODUCT_TYPES = 'analysis/FETCH_PRODUCT_TYPES';
export const FETCH_TIME_PERIODS = 'analysis/FETCH_TIME_PERIODS';
export const FETCH_FUND_NATURES = 'analysis/FETCH_FUND_NATURES';
export const FETCH_STRATEGIES = 'analysis/FETCH_STRATEGIES';
export const FETCH_TEMPLATES = 'analysis/FETCH_TEMPLATES';
export const FETCH_SUB_FUNDS = 'analysis/FETCH_SUB_FUNDS';
export const FETCH_PAGINATED_FUND_ANALYSIS =
  'analysis/FETCH_PAGINATED_FUND_ANALYSIS';
export const FETCH_ROOT_DETAILS = 'analysis/FETCH_ROOT_DETAILS';
export const FETCH_CLIENTS_BY_ORG = 'analysis/FETCH_CLIENTS_BY_ORG';
export const FETCH_SUB_FUNDS_ORG_IDS = 'analysis/FETCH_SUB_FUNDS_ORG_IDS';
export const FETCH_UPSTREAM_CLIENTS = 'analysis/FETCH_UPSTREAM_CLIENTS';
export const FETCH_INVESTMENTS_BY_ORG_IDS =
  'analysis/FETCH_INVESTMENTS_BY_ORG_IDS';
export const GENERATE_DATA_RECON_EXCEL_REPORT =
  'analysis/GENERATE_DATA_RECON_EXCEL_REPORT';
export const SEND_REPORT_EXPORT_REQUEST = 'analysis/SEND_REPORT_EXPORT_REQUEST';
export const DOWNLOAD_DATA_RECON_REPORT = 'analysis/DOWNLOAD_DATA_RECON_REPORT';
